import { createApi } from '@reduxjs/toolkit/query/react'
import {backend_server_address} from "../../../../../backendServer";
import axios from "axios";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async (axiosParams) => {
            try {
                axiosParams.url = baseUrl + axiosParams.url;
                const result = await axios.request(axiosParams);
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError;
                return {
                    error: { status: err.response ? err.response.status:undefined, data: err.response ? err.response.data:undefined },
                }
            }
        };

// Define a service using a base URL and expected endpoints
export const hardwareAPI = createApi({
    reducerPath: 'hardwareAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/hardware/v2/'}),
    tagTypes: ['Hardware'],
    endpoints: (builder) => ({
        getHardware: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(hardware => ({ type:'Hardware', id:hardware.id})), 'Hardware']
                    :[ 'Hardware' ]
            }
        }),
    }),
});

export const hardwareLegacyAPI = createApi({
    reducerPath: 'hardwareAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/hardware/'}),
    tagTypes: ['HardwareLegacy'],
    endpoints: (builder) => ({
        getMachineHardware: builder.query({
            query: (params) => ({
                url: 'machine/',
                method: 'GET',
                withCredentials:true,
                params: params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map(hardware => ({ type:'HardwareLegacy', id:hardware.id})), 'HardwareLegacy']
                    :[ 'HardwareLegacy' ]
            }
        }),
    }),
});

export const {
    useGetHardwareQuery,
} = hardwareAPI;
export const hardwareAPIReducer = hardwareAPI.reducer;
export const hardwareAPIReducerPath = hardwareAPI.reducerPath;
export const hardwareAPIReducerMiddleware = hardwareAPI.middleware;

export const {
    useGetMachineHardwareQuery,
} = hardwareLegacyAPI;
export const hardwareLegacyAPIReducer = hardwareLegacyAPI.reducer;
export const hardwareLegacyAPIReducerPath = hardwareLegacyAPI.reducerPath;
export const hardwareLegacyAPIReducerMiddleware = hardwareLegacyAPI.middleware;