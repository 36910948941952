import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';
import { Evidence } from '../components/Diagnostic/components/types';

export const evidenceAPI = createApi({
    reducerPath: 'evidenceAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/evidence/'}),
    tagTypes: ['Evidence'],
    endpoints: (builder) => ({
        getEvidence: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map((evidence: Evidence) => ({ type:'Evidence', id:evidence.id})), 'Evidence']
                    :[ 'Evidence' ]
            }
        }),
        getEvidenceById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Evidence', id: result.id}] : ['Evidence'];
            },
        }),
        createEvidence: builder.mutation({
            query: ({data}) =>({
                url: '',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Evidence'],
        }),
    }),
});