import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';
import { Problem, ProblemType } from '../components/Diagnostic/components/types';

export const problemsAPI = createApi({
    reducerPath: 'problemsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/'}),
    tagTypes: ['Problem', 'ProblemType'],
    endpoints: (builder) => ({
        getProblemTypes: builder.query({
            query: () => ({
                url: 'problem_type/',
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map((problem_type: ProblemType) => ({ type:'ProblemType', id:problem_type.id})), 'ProblemType']
                    :[ 'ProblemType' ]
            }
        }),
        getProblems: builder.query({
            query: (params) => ({
                url: 'problem/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map((problem: Problem) => ({ type:'Problem', id:problem.id})), 'Problem']
                    :[ 'Problem' ]
            }
        }),
        editProblem: builder.mutation({
            query: ({id, data}) =>({
                url: 'problem/' + id + '/',
                method: 'PATCH',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => result ? [{ type: 'Problem', id:result.id }]:['Problem'],
        }),
        createProblem: builder.mutation({
            query: ({data}) =>({
                url: 'problem/',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Problem'],
        }),
    }),
});