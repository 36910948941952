import {Route, Redirect, Switch, useLocation} from 'react-router-dom';
import React, {Suspense, useEffect, useState, lazy} from 'react';
import Loader from 'react-loaders'
import {setMobileState} from "../store/actions/themeActions/ThemeOptions";
import {useDispatch} from "react-redux";
import {importRetryReload} from "./utilities/importRetryReload";
import i18n from './utilities/i18n';
// import store from '../index';

const Platform = lazy(() => importRetryReload(() => import("./platform")));
const Login = lazy(() => importRetryReload(() => import("./landing/Login")));
const ForgotPassword = lazy(() => importRetryReload(() => import("./landing/ForgotPassword")));
const RecoverPassword = lazy(() => importRetryReload(() => import("./landing/NewPassword")));

const Notfound = () => <h1>Not found</h1>;

const AppMain = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const [previousPath, setPreviousPath] = useState("/");
    const [currentPath, setCurrentPath] = useState(location.pathname);

    useEffect(() => {
        if (props.width < 768) {
            dispatch(setMobileState(2));
        } else if (props.width < 992) {
            dispatch(setMobileState(1));
        } else {
            dispatch(setMobileState(0));
        }
    }, [props.width]);

    useEffect(() => {
        if (location.pathname !== currentPath) {
            setPreviousPath(currentPath);
            setCurrentPath(location.pathname);
        }
    }, [location.pathname]);

    // store.subscribe(() => {
    //     console.log('Redux Store Updated:', store.getState());
    // });

    return (
        <div>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale-party"/>
                        </div>
                        <h6 className="mt-3">
                            {i18n.t('Loading module')}
                        </h6>
                    </div>
                </div>
            }>
                {
                    props.prevAuth ?
                        props.isAuthenticated ?
                            <Switch>
                                <Route exact path="/login" render={() => <Redirect to={previousPath}/>}/>
                                <Route path="/dashboard" component={Platform}/>
                                <Route path="/" render={() => <Redirect to="/dashboard"/>}/>
                                <Route component={Notfound}/>
                            </Switch>
                        :
                            <div className="loader-container">
                                <div className="loader-container-inner">
                                    <div className="text-center">
                                        <Loader type="line-scale-party"/>
                                    </div>
                                    <h6 className="mt-3">
                                        {i18n.t('Authenticating')}
                                    </h6>
                                </div>
                            </div>
                    :
                        <Switch>
                            <Route path="/login" exact component={Login}/>
                            <Route path="/recover" exact component={RecoverPassword}/>
                            <Route path="/dashboard" render={() => <Redirect to="/login"/>}/>
                            <Route path="/forgot-password" exact component={ForgotPassword}/>
                            <Route render={() => <Redirect to="/login"/>}/>
                        </Switch>
                }
            </Suspense>
        </div>
    )
};

export default AppMain;