import { updateObject} from "../../utility";
import * as actionTypes from "./AuthActions";

const  initialState = {
    token_access: null,
    token_access_expiration: null,
    token_refresh: null,
    token_refresh_expiration: null,
    authenticated:false,
    error: null,
    waiting_authentication: true,
    connection_error: false,
    permissions: [],
}

const authStart = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const authSuccess = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        ...action,
    });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        ...action,
    });
}

const authRefresh = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const authInvalidValues = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const recoverPasswordMail = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const recoverPasswordError = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const recoverPasswordFail = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const recoverPasswordSuccess = (state, action) => {
    return updateObject(state,{
        ...action,
    });
}

const authReducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state,action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state,action);
        case actionTypes.AUTH_FAIL: return authFail(state,action);
        case actionTypes.AUTH_INVALID_VALUES: return authInvalidValues(state,action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state,action);
        case actionTypes.AUTH_REFRESH: return authRefresh(state,action);
        case actionTypes.RECOVER_PASSWORD_MAIL: return recoverPasswordMail(state,action);
        case actionTypes.RECOVER_PASSWORD_ERROR: return recoverPasswordError(state,action);
        case actionTypes.RECOVER_PASSWORD_FAIL: return recoverPasswordFail(state,action);
        case actionTypes.RECOVER_PASSWORD_SUCCESS: return recoverPasswordSuccess(state,action);

        default:
            return state;
    }
}

export default authReducer;