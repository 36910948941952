import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';
import { Finding, FindingType } from '../components/Diagnostic/components/types';

export const findingsAPI = createApi({
    reducerPath: 'findingsAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/'}),
    tagTypes: ['Finding', 'FindingType'],
    endpoints: (builder) => ({
        getFindingTypes: builder.query({
            query: () => ({
                url: 'finding_type/',
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map((finding_type: FindingType) => ({ type:'FindingType', id:finding_type.id})), 'FindingType']
                    :[ 'FindingType' ]
            }
        }),
        getFindings: builder.query({
            query: (params) => ({
                url: 'finding/',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (result) => {
                return result ?
                    [...result.map((finding: Finding) => ({ type:'Finding', id:finding.id})), 'Finding']
                    :[ 'Finding' ]
            }
        }),
        createFinding: builder.mutation({
            query: ({data}) =>({
                url: 'finding/',
                method: 'POST',
                withCredentials:true,
                data:data
            }),
            invalidatesTags: (result) => ['Finding'],
        }),
    }),
});