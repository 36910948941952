import { createApi } from '@reduxjs/toolkit/query/react';
import {backend_server_address} from "../../../../../backendServer";
import { axiosBaseQuery } from '../../../../../store/utility';

export const featuresAPI = createApi({
    reducerPath: 'featuresAPI',
    baseQuery: axiosBaseQuery({ baseUrl: backend_server_address+'/api/machine_learning/feature/'}),
    tagTypes: ['Feature', 'FeatureMeasurements'],
    endpoints: (builder) => ({
        getFeatures: builder.query({
            query: (params) => ({
                url: '',
                method: 'GET',
                withCredentials:true,
                params:params
            }),
            providesTags: (results) => ['FeatureMeasurements']
        }),
        getFeatureById: builder.query({
            query: (id) => ({
                url: `${id}/`,
                method: 'GET',
                withCredentials:true,
            }),
            providesTags: (result) => {
                return result ? [{type: 'Feature', id: result.id}] : ['Feature'];
            },
        }),
    }),
});